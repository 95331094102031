<template>
  <div class="user-manage-container">
    <div class="left-wrapper">
      <div>
        <a-input-search
          style="margin-bottom: 8px"
          placeholder="请输入部门名称"
          @change="onChange"
          v-model="searchValue"
        />
        <a-tree
          :expanded-keys="expandedKeys"
          :auto-expand-parent="autoExpandParent"
          :tree-data="gData"
          @expand="onExpand"
          :defaultExpandAll="true"
          :replaceFields="{
            children: 'children',
            title: 'label',
            key: 'key',
          }"
        >
          <template #title="data">
            <span
              @click="handleDataId(data)"
              :title="data.label"
              :class="{
                titleColor: data.label.includes(searchValue),
              }"
              >{{ data.label }}</span
            >
          </template>
        </a-tree>
      </div>
    </div>
    <div class="right-wrapper">
      <div class="top-container">
        <a-row class="mb2">
          <div class="layout-inline mb2">
            <div>
              <span class="label">用户名称：</span>
              <a-input placeholder="请输入" v-model="queryFrom.userName" style="width: 70%" />
            </div>
            <div>
              <span class="label">手机号码：</span>
              <a-input placeholder="请输入" v-model="queryFrom.phonenumber" style="width: 70%" />
            </div>
            <div>
              <span class="label">状态：</span>
              <a-select
                show-search
                placeholder="请选择"
                :getPopupContainer="
                  (triggerNode) => handleDislocation(triggerNode)
                "
                :default-active-first-option="false"
                :style="{ width: '28.5rem' }"
                :not-found-content="null"
                v-model="queryFrom.status"
              >
                <a-select-option
                  v-for="(item, i) in stateList"
                  :value="item.value"
                  :key="i"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div class="layout-inline">
            <div>
              <span class="label">创建时间：</span>
              <a-range-picker
                format="YYYY-MM-DD HH:mm:ss"
                @change="handleDate"
                style="width: 40rem"
                v-model="dateTime"
              />
            </div>
            <div class="operationBtn">
              <a-button type="reload" icon="redo" @click="handleReset"
                >重置</a-button
              >
              <a-button type="primary" icon="search" @click="queryBtn"
                >查询</a-button
              >
            </div>
          </div>
        </a-row>
      </div>

      <div class="bottom-container">
        <a-button class="mb2 mr2" type="primary" @click="openModal('add')"
          >新增</a-button
        >
        <a-button class="mb2" @click="userBatchDelete">批量删除</a-button>
        <a-table
          :columns="columns"
          :data-source="data"
          :key="data.key"
          :loading="loading"
          :scroll="{ y: 400 }"
          :row-selection="{
            selectedRowKeys: selectedRowKeys,
            onChange: onSelectChange,
          }"
          :pagination="pagination"
        >
          <template slot="status" slot-scope="text, record">
            <a-switch
              :checked="record.status === '0' ? true : false"
              @click="handleSwitch(text, record.userId, record)"
            />
          </template>

          <template slot="operation" slot-scope="text, record">
            <span
              class="hover"
              :style="{ color: `${getColor('主题')}` }"
              v-if="record.userId !== 1"
              @click="openModal('edit', record.userId, record)"
              >修改</span
            >
            <span
              class="hover"
              :style="{ color: `${getColor('主题')}` }"
              @click="handleDelete(record.userId, record)"
              >删除</span
            >
            <span
              class="hover"
              @click="openModal('reset', record.userId, record)"
              v-if="record.userId !== 1"
              :style="{ color: `${getColor('主题')}` }"
              >重置</span
            >
          </template>
        </a-table>
      </div>
    </div>
    <UserModal :userModal="userModal" v-if="userModal" :config="configFrom" />
    <ResetModal
      :resetModal="resetModal"
      v-if="resetModal"
      :config="configFrom"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "用户编码",
    ellipsis: true,
    dataIndex: "userId",
    scopedSlots: { customRender: "userId" },
  },
  {
    title: "用户名称",
    ellipsis: true,
    dataIndex: "userName",
    scopedSlots: { customRender: "userName" },
  },
  {
    title: "用户昵称",
    ellipsis: true,
    dataIndex: "nickName",
    scopedSlots: { customRender: "nickName" },
  },
  {
    title: "部门",
    ellipsis: true,
    dataIndex: "deptName",
    scopedSlots: { customRender: "deptName" },
  },
  {
    title: "手机号码",
    ellipsis: true,
    dataIndex: "phonenumber",
    scopedSlots: { customRender: "phonenumber" },
  },
  {
    title: "状态",
    ellipsis: true,
    dataIndex: "status",
    scopedSlots: { customRender: "status" },
  },
  {
    title: "创建时间",
    ellipsis: true,
    dataIndex: "createTime",
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "操作",
    ellipsis: true,
    dataIndex: "operation",
    width: "200px",
    scopedSlots: { customRender: "operation" },
  },
];

import mixins from "@/mixins/mixins.js";
import pagination from "@/mixins/Pagination.js";
import { getColor } from "@/utils/color";
import { getMenuName } from "@/utils/utils.js";
import UserModal from "./userModal.vue";
import ResetModal from "./resetModal.vue";
import { API } from "@/apix/userManage.js";
import { APID } from "@/apix/department.js";
import { tips } from "../../utils/tips";
import moment from "moment";

export default {
  mixins: [pagination, mixins],
  components: {
    UserModal,
    ResetModal,
  },
  data() {
    return {
      columns,
      data: [],
      selectedRowKeys: [],
      loading: false,
      queryFrom: {
        userName: null, // 用户名称
        phonenumber: null, // 手机号
        status: undefined, // 状态
        startTime: null, // 开始时间
        endTime: null, // 结束时间
        deptId: null, // 部门id
      },
      stateList: [
        { name: "正常", value: 0 },
        { name: "停用", value: 1 },
      ],
      configFrom: {
        id: null,
        type: null,
        record: [],
      },
      userModal: false, // user弹框
      resetModal: false, // 重置密码
      gData: [],
      dataList: [],
      expandedKeys: [],
      searchValue: null,
      autoExpandParent: true,
      dateTime: null,
    };
  },
  methods: {
    getColor,
    getMenuName,
    openModal(type, id, record) {
      if (type === "reset") {
        this.resetModal = true;
        this.configFrom.id = id;
        this.configFrom.type = type;
        this.configFrom.record = record;
      } else if (type === "add" || type === "edit") {
        this.userModal = true;
        this.configFrom.id = id;
        this.configFrom.type = type;
        this.configFrom.record = record;
      }
    },
    closeModal() {
      this.userModal = false;
      this.resetModal = false;
    },
    //处理日期
    handleDate(date) {
      this.queryFrom.startTime = moment(date[0]).format("YYYY-MM-DD HH:mm:ss");
      this.queryFrom.endTime = moment(date[1]).format("YYYY-MM-DD HH:mm:ss");
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    getSourceData() {
      let detail = {
        page: this.pagination.current,
        size: this.pagination.pageSize,
        ...this.queryFrom,
      };
      this.loading = true;
      API.pageIndex(detail).then((res) => {
        this.pagination.total = Number(res.data.total);
        this.data = res.data.records.map((i) => {
          i.key = i.id;
          return i;
        });
        this.loading = false;
      });
    },

    // 删除用户
    handleDelete(id, record) {
      if (this.data.length < 2) {
        this.pagination.current--;
      }
      let self = this;
      this.$confirm({
        content: `是否确认删除用户编号为 ${record.userId} 的数据`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.userDelete(id).then((res) => {
            tips(res, "删除");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    // 批量删除用户
    userBatchDelete() {
      if (this.selectedRowKeys.length === 0) {
        return;
      }
      if (this.data.length === this.selectedRowKeys.length) {
        this.pagination.current--;
      }
      let userIds = [];
      this.selectedRowKeys.forEach((item) => {
        userIds.push(this.data[item].userId);
      });

      let self = this;
      this.$confirm({
        content: `是否确认删除所选用户`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.userBatchDelete(userIds).then((res) => {
            if (res.success) {
              self.selectedRowKeys = [];
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    // 启用用户
    userEnable(id, record) {

      let self = this;
      this.$confirm({
        content: `是否确定启用 ${record.userName} 用户？`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.userEnable(id).then((res) => {
            tips(res, "启用");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    // 停用用户
    userStop(id, record) {
      let self = this;
      this.$confirm({
        content: `是否确定停用 ${record.userName} 用户？`,
        cancelText: "取消",
        okText: "确定",
        onOk() {
          self.loading = true;
          API.userStop(id).then((res) => {
            tips(res, "停用");
            if (res.success) {
              self.getSourceData();
            }
            self.loading = false;
          });
        },
      });
    },

    // 根据部门模糊搜索
    handleDataId(data) {
      this.queryFrom.deptId = data.id;
      this.pagination.current = 1;
      this.getSourceData();
    },

    // 用户列表开关处理
    handleSwitch(val, id, record) {
      if (val === "0") {
        this.userStop(id, record);
      } else if (val === "1") {
        this.userEnable(id, record);
      }
    },

    // 重置按钮
    handleReset() {
      this.pagination.current = 1;
      Object.keys(this.queryFrom).forEach((key) => {
        this.queryFrom[key] = null;
      });
      this.queryFrom.status = undefined;
      this.searchValue = null;
      this.dateTime = null;
      this.getSourceData();
    },

    onExpand(expandedKeys) {
      this.expandedKeys = expandedKeys;
      this.autoExpandParent = false;
    },
    onChange(e) {
      const value = e.target.value;

      const expandedKeys = this.dataList
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return this.getParentKey(item.key, this.gData);
          }
          return null;
        })
        .filter((item, i, self) => item && self.indexOf(item) === i);
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      });
    },

    generateList(data) {
      for (let i = 0; i < data.length; i++) {
        const node = data[i];
        const key = node.key;
        this.dataList.push({ key, title: data[i].label });
        if (node.children) {
          this.generateList(node.children);
        }
      }
    },

    getParentKey(key, tree) {
      let parentKey;
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key;
          } else if (this.getParentKey(key, node.children)) {
            parentKey = this.getParentKey(key, node.children);
          }
        }
      }
      return parentKey;
    },

    // 部门管理可用树形结构
    departmentAvaTree() {
      APID.departmentAvaTree().then((res) => {
        if (res.success) {
          this.gData = res.data;
          this.getNewData(this.gData);
          this.generateList(this.gData);
        }
      });
    },

    getNewData(arr) {
      arr.map((item) => {
        item.key = item.id;
        if (item.children) {
          this.getNewData(item.children);
        }
        return arr;
      });
    },
  },
  created() {
    this.departmentAvaTree();
    this.getSourceData();
  },
};
</script>

<style lang="scss" scoped>
.user-manage-container {
  width: 100%;
  display: flex;
  .left-wrapper {
    width: 15%;
    height: 99%;
    background: #fff;
    margin-right: 2rem;
    padding: 1rem;
  }
  .right-wrapper {
    width: 85%;
  }
}
.layout-inline {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.top-container {
  height: 16rem;
}
.bottom-container {
  min-height: 78%;
}
.hover {
  margin-right: 2rem;
}
.label {
  width: 14rem !important;
  text-align: right;
  line-height: 3.5rem;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
}
.operationBtn {
  button {
    margin-left: 2rem;
  }
}
.mb2 {
  margin-bottom: 2rem;
}
.mr3 {
  margin-left: 2.8rem;
}
.mr2 {
  margin-left: 2rem;
}
.titleColor {
  color: #f50;
}
.ant-input {
  width: 28.5rem;
}
/deep/ .ant-tree li .ant-tree-node-content-wrapper {
  overflow: hidden;
  width: 15rem;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
