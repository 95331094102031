<template>
  <div class="reset-modal-container">
    <a-modal
      :visible="resetModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      cancelText="返回"
      :maskClosable="false"
      title="重置提示"
      :width="500"
    >
      <div>
        请输入用户' <span>{{ config.record.userName }}</span> '新密码
      </div>
      <a-input placeholder="请输入" v-model="password"></a-input>
    </a-modal>
  </div>
</template>

<script>
import mixins from "@/mixins/mixins.js";
import { API } from "@/apix/userManage.js";
import { tips } from "../../utils/tips";
export default {
  mixins: [mixins],
  props: {
    resetModal: {
      type: Boolean,
      default: false,
    },
    config: {
      default: () => {},
    },
  },
  data() {
    return {
      password: null,
    };
  },
  methods: {
    handleOk() {
      this.userResetPassword();
    },
    userResetPassword() {
      let result = RegExp(/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/).test(
        this.password
      );
      if (!this.password) {
        this.$message.error("请输入密码");
      } else if (!result) {
        this.$message.error("密码必须为至少8位,最大16位的数字字母组合");
      } else {
        let detail = {
          password: this.password,
          userId: this.config.record.userId,
        };
        API.userResetPassword(detail).then((res) => {
          tips(res, "重置密码");
          if (res.success) {
            this.cancel();
            this.$parent.getSourceData();
          } else {
            this.$message.error(res.errorMsg);
          }
        });
      }
    },
  },

  created() {},
};
</script>

<style lang="scss" scoped>
.ant-input {
  width: 28rem;
}
</style>
