import axios from "@/config/http.js";

// 用户管理
export const API = {
  // 用户首页
  async pageIndex(params) {
    return await axios.get("/sysUser/list", { params });
  },
  // 启用用户
  async userEnable(userId) {
    return await axios.put(`/sysUser/statrUser/${userId}`);
  },
  // 停用用户
  async userStop(userId) {
    return await axios.put(`/sysUser/stopUser/${userId}`);
  },
  // 删除用户
  async userDelete(userId) {
    return await axios.delete(`/sysUser/${userId}`);
  },
  // 批量删除用户
  async userBatchDelete(params) {
    return await axios.post(`/sysUser/batch`, params);
  },
  // 新增用户
  async userAdd(params) {
    return await axios.post("/sysUser", params);
  },
  // 修改用户
  async userEdit(params) {
    return await axios.put("/sysUser", params);
  },
  // 重置用户密码
  async userResetPassword(params) {
    return await axios.put("/sysUser/resetPasswd", params);
  },
};
