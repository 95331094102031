<template>
  <div class="user-modal-container">
    <a-modal
      :visible="userModal"
      class="modal-style"
      @cancel="cancel"
      @ok="handleOk"
      cancelText="返回"
      :maskClosable="false"
      :width="900"
    >
      <template slot="title">
        <span v-if="config.type === 'add'">添加用户</span>
        <span v-else>修改用户</span>
      </template>

      <a-form-model
        layout="inline"
        ref="ruleForm"
        :model="submitFrom"
        :rules="rules"
      >
        <div class="layout-inline mb2">
          <a-form-model-item label="用户昵称" prop="nickName">
            <a-input
              placeholder="请输入"
              v-model="submitFrom.nickName"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="归属部门" prop="deptId">
            <a-tree-select
              style="width: 28.5rem"
              :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
              :tree-data="treeData"
              placeholder="请选择"
              tree-default-expand-all
              :replaceFields="{
                children: 'children',
                title: 'label',
                key: 'key',
                value: 'id',
              }"
              v-model="submitFrom.deptId"
              @change="handleDepartment"
            >
            </a-tree-select>
          </a-form-model-item>
        </div>

        <div class="layout-inline mb2">
          <a-form-model-item
            label="手机号码"
            class="starVisible"
            prop="phonenumber"
          >
            <a-input
              placeholder="请输入"
              v-model="submitFrom.phonenumber"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="邮箱" prop="email">
            <a-input placeholder="请输入" v-model="submitFrom.email"></a-input>
          </a-form-model-item>
        </div>

        <div class="layout-inline mb2" v-if="config.type === 'add'">
          <a-form-model-item label="用户名称" prop="userName">
            <a-input
              placeholder="请输入"
              v-model="submitFrom.userName"
            ></a-input>
          </a-form-model-item>
          <a-form-model-item label="用户密码" prop="password">
            <a-input
              placeholder="请输入"
              v-model="submitFrom.password"
            ></a-input>
          </a-form-model-item>
        </div>

        <div class="layout-inline mb2">
          <a-form-model-item label="用户性别" class="starVisible">
            <a-select
              show-search
              placeholder="请选择"
              :getPopupContainer="
                (triggerNode) => handleDislocation(triggerNode)
              "
              :default-active-first-option="false"
              :style="{ width: '28.5rem' }"
              :not-found-content="null"
              v-model="submitFrom.sex"
            >
              <a-select-option
                v-for="(item, i) in userGenderList"
                :value="item.value"
                :key="i"
              >
                {{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label="状态" prop="status">
            <a-radio-group
              :default-value="1"
              style="width: 28.5rem"
              v-model="submitFrom.status"
            >
              <a-radio :value="'0'"> 正常 </a-radio>
              <a-radio :value="'1'"> 停用 </a-radio>
            </a-radio-group>
          </a-form-model-item>
        </div>
      </a-form-model>

      <a-table
        :columns="columns"
        :data-source="data"
        :key="data.key"
        :scroll="{ y: 400 }"
        :row-selection="{
          selectedRowKeys: selectedRowKeys,
          onChange: onSelectChange,
        }"
        :pagination="false"
      ></a-table>

      <a-form-model layout="inline" class="layout-inline mt2">
        <a-form-model-item label="备注">
          <a-textarea
            placeholder="请输入"
            style="width: 70rem"
            :auto-size="{ minRows: 4 }"
            :maxLength="64"
            v-model="submitFrom.remark"
          ></a-textarea>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "角色名称",
    ellipsis: true,
    dataIndex: "name",
    width: "30%",
    scopedSlots: { customRender: "name" },
  },
  {
    title: "说明",
    // ellipsis: true,
    dataIndex: "describle",
    width: "70%",
    scopedSlots: { customRender: "describle" },
  },
];
import mixins from "@/mixins/mixins.js";
import { userRule } from "@/utils/rulex.js";
import { APID } from "@/apix/department.js";
import { API } from "@/apix/userManage.js";
import { tips } from "../../utils/tips";
export default {
  mixins: [mixins],
  props: {
    userModal: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      columns,
      rules: userRule,
      data: [
        {
          name: "业务管理员",
          describle:
            "审核生产延期、审核提确认前结单（订单强制关闭），修改选品分析（修改浏览量、询盘量、成交量），工厂评价标准制定、编辑和启用，订单审核。以及订单操作员所有权限。（不含运营操作员权限）",
        },
        {
          name: "订单操作员",
          describle:
            "发起生产延期，发起订单强制关闭、工厂评价打分、上架申请和审核、商户审核、订单暂存和编辑",
        },
        {
          name: "运营操作员",
          describle: "仅有广告管理、爆品管理、新品管理权限",
        },
      ],
      selectedRowKeys: [],
      treeData: [
        // {
        //   title: "Node1",
        //   value: "0-0",
        //   key: "0-0",
        //   children: [
        //     {
        //       title: "dfs",
        //       value: "0-0-1",
        //       key: "0-0-1",
        //     },
        //     {
        //       title: "Child Node2",
        //       value: "0-0-2",
        //       key: "0-0-2",
        //     },
        //   ],
        // },
        // {
        //   title: "Node2",
        //   value: "0-1",
        //   key: "0-1",
        // },
      ],
      userGenderList: [
        { name: "男", value: "0" },
        { name: "女", value: "1" },
        { name: "未知", value: "2" },
      ],
      submitFrom: {
        deptId: null, // 归属部门id
        deptName: null, // 归属部门名称
        email: null, // 用户邮箱
        userName: null, // 用户名称
        nickName: null, // 用户昵称
        password: null, // 用户密码
        phonenumber: null, // 手机号码
        roleIds: [], // 角色组
        sex: null, // 用户性别
        status: null, // 账号状态（0正常，1停用）
        remark: null, // 备注
      },
    };
  },
  methods: {
    handleOk() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.config.type === "add") {
            this.userAdd();
          } else if (this.config.type === "edit") {
            this.userEdit();
          }
        }
      });
    },
    // 回填数据
    backFillData(data) {
      for (const key in this.submitFrom) {
        this.submitFrom[key] = data[key];
      }
      this.selectedRowKeys = data.roleIds.map((item) => {
        item -= 3;
        return item;
      });
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 新增角色
    userAdd() {
      this.submitFrom.roleIds = this.selectedRowKeys.map((item) => {
        item += 3;
        return item;
      });
      API.userAdd(this.submitFrom).then((res) => {
        tips(res, "新增");
        if (res.success) {
          this.cancel();
          this.$parent.getSourceData();
        }
      });
    },
    // 编辑角色
    userEdit() {
      this.submitFrom.userId = this.config.id;
      this.submitFrom.roleIds = this.selectedRowKeys.map((item) => {
        item += 3;
        return item;
      });
      API.userEdit(this.submitFrom).then((res) => {
        tips(res, "编辑");
        if (res.success) {
          this.cancel();
          this.$parent.getSourceData();
        }
      });
    },

    // 拿取可用树信息
    departmentAvaTree() {
      APID.departmentAvaTree().then((res) => {
        this.treeData = res.data;
      });
    },

    // 处理部门
    handleDepartment() {
      this.$refs.ruleForm.clearValidate();
    },
  },
  created() {
    this.departmentAvaTree();
    if (this.config.type === "edit") {
      this.backFillData(this.config.record);
    }
  },
};
</script>

<style lang="scss" scoped>
.layout-inline {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}
.ant-input {
  width: 28.5rem;
}
.mb2 {
  margin-bottom: 2rem;
}
.mt2 {
  margin-top: 2rem;
}
/deep/ .ant-form-inline .ant-form-item-with-help {
  margin-bottom: 0;
}
</style>
